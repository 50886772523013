import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout>
    <SEO title="THACO | Contest" name="contest" />
    <Header page="about" />
    <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="text-center mb-5">
          <span className="bg-dark px-2 py-1">เกี่ยวกับการแข่งขัน</span>
        </h2>
        <p className="text-white">
          การแข่งขัน Thailand Computing Olympiad 2020
          เป็นการแข่งขันที่จัดขึ้นโดยกลุ่มนักเรียนเก่าค่ายคอมพิวเตอร์โอลิมปิก
          การแข่งขันนี้แบ่งออกเป็น 2 วัน แต่ละวันจะมีระยะเวลาการแข่งขันยาว 3
          ชั่วโมง ภายใต้เวลาการแข่งขัน ผู้เข้าแข่งขันทุกคนจะได้รับโจทย์เหมือนกัน
          3 ข้อ เป็นโจทย์แข่งขันคอมพิวเตอร์โอลิมปิก
          สามารถเทียบเคียงได้กับโจทย์ใน{" "}
          <Link className="sans pb-0" to="https://ioinformatics.org">
            การแข่งขันคอมพิวเตอร์โอลิมปิกระหว่างประเทศ (International Olympiad
            in Informatics, IOI)
          </Link>{" "}
          แต่จะมีข้อแตกต่างคือ การแข่งขันนี้จะใช้
          <Link to="/scope" className="sans pb-0">
            ขอบเขตเนื้อหา
          </Link>
          ไม่เกินการแข่งขันคอมพิวเตอร์โอลิมปิกระดับชาติ (Thailand Olympiad in
          Informatics, TOI) และโจทย์ทุกข้อจะใช้การเขียนอ่านข้อมูลทาง standard
          input (หน้าจอ) และ standard output (คีย์บอร์ด) เท่านั้น
          จะไม่มีโจทย์ประเภท interactive หรือ output-only
        </p>
        <p className="text-white">
          ภายในการแข่งขัน จะอนุญาตให้ใช้เฉพาะภาษา C และ C++ เท่านั้น
          โดยมีรุ่นของภาษาเป็น C11 และ C++11 ตามลำดับ การแข่งขันจะใช้{" "}
          <Link className="sans pb-0" to="https://cms-dev.github.io/">
            ระบบจัดการแข่งขัน CMS (Contest Management System)
          </Link>{" "}
        </p>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default AboutPage
